
import TheMainContainer from "~/components/container/TheMain.vue";
import TheMessage from "~/layouts/message.vue";

export default {
  name: "default",
  head: {
    title: '顧客管理AGS',
  },
  components: {
    TheMainContainer,
    TheMessage,
  }
}
