
export default {
  name: "TheMessage",
  head: {
    title: '顧客管理AGS',
  },
  props: {
    type: {
      type: String,
      require: true
    },
    message: {
      type: String,
      require: false
    },
  },
}
