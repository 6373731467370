import {GqlService} from "~/services/gql-service.js";
import constMessages from "~/const/messages";

export const AppLogic = {
  /**
   * システムメッセージをセット
   * @param app
   * @param msg
   * @returns void
   */
  async setSystemMessage(app, msg) {
    await GqlService.app.changeSystemMessage(app, msg);
  },

  /**
   * 店舗変更の処理, Set data for global components
   * @param app
   * @param user
   * @returns {Promise<void>}
   */
  async changeShopGlobal(app, {shopId, shopCompanyId}) {
    try {
      const data = await GqlService.app.selectStaffListAction(app, {shopId, shopCompanyId});

      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  async changeShopForComponents(app, {shopId}, isStaffGlobalUpdate = false){
    try {
      const data = await GqlService.app.selectStaffListForComponentsAction(app, {shopId}, isStaffGlobalUpdate);

      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 都道府県の取得処理
   * @param app
   * @returns Promise<T>
   */
  async selectTodohukenList(app) {
    try {
      const data = await GqlService.app.selectTodohukenListAction(app);
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 市区町村の取得処理
   * @param app
   * @param todohuken_cd
   * @returns Promise<T>
   */
  async changeSikucyosonList(app, todohuken_cd) {
    try {
      const data = await GqlService.app.changeSikucyosonListAction(app, {todohuken_cd});
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 町の取得処理
   * @param app
   * @param todohuken_cd
   * @param sikucyoson_cd
   * @returns Promise<T>
   */
  async changeCyoList(app, todohuken_cd, sikucyoson_cd) {
    try {
      const data = await GqlService.app.changeCyoListAction(app, {todohuken_cd, sikucyoson_cd});
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 丁目の取得処理
   * @param app
   * @param todohuken_cd
   * @param sikucyoson_cd
   * @param cyo_cd
   * @returns Promise<T>
   */
  async changeCyomokuList(app, todohuken_cd, sikucyoson_cd, cyo_cd) {
    try {
      const data = await GqlService.app.changeCyomokuListAction(app, {
        todohuken_cd,
        sikucyoson_cd,
        cyo_cd
      });
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 郵便番号から住所CDの取得
   * @param app
   * @param zip
   */
  async changeZip(app, zip) {
    try {
      const data = await GqlService.app.changeZipAction(app, zip);
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 学校の取得処理
   * @param app
   * @param todohuken
   * @param sikucyoson
   * @returns void
   */
  async changeSchoolList(app, todohuken, sikucyoson) {
    try {
      const data = await GqlService.app.changeSchoolListAction(app, {todohuken, sikucyoson});
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 沿線・駅の取得処理
   * @param app
   * @param todohuken
   */
  async changeEnsenEkiList(app, todohuken) {
    try {
      const data = await GqlService.app.changeEnsenEkiListAction(app, {todohuken});
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 物件一覧の取得処理
   * @param app
   * @param variables {azukariShubetsus, companyName, createdAtEnd, createdAtStart, cyoCd, ekiId, ensenId, kakakuEnd, kakakuStart, property, shop, sikucyosonCd, todohukenCd, type}
   */
  async changePropertyList(app, variables) {
    try {
      const data = await GqlService.app.changePropertyListAction(app, variables);
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * メールアドレスからスタッフ情報取得
   * @param app
   * @param variables
   */
  async selectStaffFromMail(app, {mail, shop}) {
    try {
      const data = await GqlService.app.selectStaffFromMailAction(app, {mail, shop});
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * メールの物件の取得処理
   * @param app
   * @param variables
   */
  async selectProperty(app, variables) {
    try {
      const data = await GqlService.app.selectPropertyAction(app, variables);
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * @param app
   * @param variables
   * @returns {Promise<*>}
   */
  async selectPropertyInfor(app, variables) {
    try {
      const data = await GqlService.app.selectPropertyInforAction(app, variables);
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 会社リストの取得
   * @param app
   * @param keyword
   * @param shop
   * @returns Promise<T>
   */
  async selectCompanyList(app, {keyword, shop}) {
    try {
      const data = await GqlService.app.selectCompanyListAction(app, {keyword, shop});
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },

  /**
   * 物件情報画面に載せる店舗情報を取得
   * @param app
   * @param kibou
   */
  async selectShopDataFromKibou(app, {kibou}) {
    try {
      const data = await GqlService.app.selectShopDataFromKibouAction(app, {kibou});
      return data;
    } catch (e) {
      this.setSystemMessage(app, {
        isShow: true,
        isFlash: false,
        type: 'error',
        message: constMessages.api.appLogic.errorAppLogicApi
      });
    }
  },
}
