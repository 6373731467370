
import TheMainContainer from "~/components/container/TheMain.vue";
import TheMessage from "~/layouts/message.vue";

export default {
  name: 'maisoku',
  components: {
    TheMainContainer,
    TheMessage,
  }
}
