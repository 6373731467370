

export default {
  name: "lineLIFFApp",
  head: {
    title: 'LINEフォーム',
    meta: [
      {charset: 'utf-8'},
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {hid: 'description', name: 'description', content: '情報を入力するとスタッフと個別やりとりが可能になります！' || ''},
      {hid: 'og:site_name', property: 'og:site_name', content: 'LINEフォーム'},
      {hid: 'og:title', property: 'og:title', content: 'LINEフォーム'},
      {hid: 'og:description', property: 'og:description', content: '情報を入力するとスタッフと個別やりとりが可能になります！'},
    ],
  },
}
