import _ from "lodash";
import constProperty from "~/const/property";
import {getArea, getBukkenType, getShubetsuGroupValues} from "./common";
import {removeZeros, calcAreaM2T} from "./format";
import constProject from "~/const/project";

const DEFAULT_IMAGE = 'https://sis-customer-api-prd-file.s3.ap-northeast-1.amazonaws.com/noImage.png';

/**
 * LINE物件のHTMLテンプレート
 * @param property
 * @return {string}
 */
export const getLinePropertyHTML = property => {
  if (!property.property) return '';
  const propertyImageUrl = getPropertyImagePath(property);
  const propertyType = getBukkenType(property);
  !_.isNil(property.propertyPrice?.kakaku)

  const priceLabel = !_.isNil(property.propertyPrice?.kakaku) ? `${parseFloat(property.propertyPrice.kakaku).toLocaleString()}万円` : '未設定';

  return `<div class="c-linePropertyBlock">
            <div class="c-linePropertyBlockImage"><img src="${propertyImageUrl}"></div>
            <div class="c-linePropertyBlockContent">
              <div class="c-linePropertyBlockContent__title">＜${propertyType}＞物件番号：${property.property}</div>
              <div class="c-linePropertyBlockContent__price">${priceLabel}</div>
              ${getTochiMensekiHTML(property)}
              ${getTatemonoMensekiHTML(property)}
              ${getSenyuuMensekiHTML(property)}
              ${getChimokuHTML(property)}
              ${getAddressHTML(property)}
              ${getAnotherDataHTML(property)}
              <div class="c-linePropertyBlockContent__button"><a href="/property/${property.property}" class="c-greenButton" target="_blank">物件情報</a></div>
            </div>
        </div>`;
}

/**
 * 土地面積のHTML取得
 * @param property
 * @return {string}
 */
const getTochiMensekiHTML = property => {
  // 土地面積の情報が未設定の場合、表示しない
  if (_.isNil(property.propertyLand?.tochi_menseki) && _.isEmpty(property.propertyLand?.tochi_keisoku_houshiki)) return '';

  // 物件が「戸建」「土地」「事業用」以外の場合、表示しない
  if (!_.includes([...getShubetsuGroupValues('戸建'), ...getShubetsuGroupValues('土地'), ...getShubetsuGroupValues('事業用')], property.basicProperty.shubetsu)) return '';

  const tochikeisokuLabel = property.propertyLand.tochi_keisoku_houshiki === '1' ? '公簿' : '実測';
  return `<div class="c-linePropertyBlockContent__menseki">土地面積：${removeZeros(property.propertyLand.tochi_menseki)}m<sup>2</sup>（${calcAreaM2T(property.propertyLand.tochi_menseki)}）${tochikeisokuLabel}</div>`
}

/**
 * 建物面積のHTML取得
 * @param property
 * @return {string}
 */
const getTatemonoMensekiHTML = property => {
  // 建物面積の情報が未設定の場合、表示しない
  if (_.isNil(property.propertyLand?.tatemono_nobe_menseki)) return '';

  // 物件が「戸建」「事業用」以外の場合、表示しない
  if (!_.includes([...getShubetsuGroupValues('戸建'), ...getShubetsuGroupValues('事業用')], property.basicProperty.shubetsu)) return '';

  return `<div class="c-linePropertyBlockContent__menseki">建物面積：${removeZeros(property.propertyLand.tatemono_nobe_menseki)}m<sup>2</sup>（${calcAreaM2T(property.propertyLand.tatemono_nobe_menseki)}）</div>`
}

/**
 * 専有面積のHTML取得
 * @param property
 * @return {string}
 */
const getSenyuuMensekiHTML = property => {
  const isMansionRoom = getBukkenType(property) === constProject.shubetsuMansion;
  const isExistAttribute = property => !_.isNil(property?.propertyLand?.senyuu_menseki) && !_.isEmpty(property?.propertyLand?.senyuu_houshiki);

  // マンション部屋の専有面積を参照するか、マンション棟の専有面積を参照するか
  let targetProperty = {};
  if (isMansionRoom && isExistAttribute(property)) {
    targetProperty = Object.assign(property, {});
  } else if (_.includes(getShubetsuGroupValues('マンション'), property.parentProperty?.basicProperty?.shubetsu) && isExistAttribute(property.parentProperty)) {
    targetProperty = Object.assign(property.parentProperty, {});
  } else {
    return '';
  }

  const senyuuKeisokuLabel = targetProperty.propertyLand.senyuu_houshiki === '1' ? '壁芯' : '内法';
  return `<div class="c-linePropertyBlockContent__menseki">専有面積：${removeZeros(targetProperty.propertyLand.senyuu_menseki)}m<sup>2</sup>（${calcAreaM2T(targetProperty.propertyLand.senyuu_menseki)}）${senyuuKeisokuLabel}</div>`
}

/**
 * 土地地目のHTML取得
 * @param property
 * @return {string}
 */
const getChimokuHTML = property => {
  if (_.isEmpty(property.propertyLand?.chimoku)) return '';
  if (!_.includes(getShubetsuGroupValues('土地'), property.basicProperty.shubetsu)) return '';

  const label = _.find(constProperty.chimoku, (chimoku) => chimoku.value === property.propertyLand.chimoku);
  const chimokuLabel = label ? label.text : 'その他';

  return `<div class="c-linePropertyBlockContent__menseki">地目：${chimokuLabel}</div>`
}

/**
 * 物件住所のHTML取得
 * @param property
 * @return {string|string}
 */
const getAddressHTML = property => {
  let targetProperty = {};
  if (property.parentProperty) {
    targetProperty = Object.assign(property.parentProperty, {});
  } else {
    targetProperty = Object.assign(property, {});
  }

  const address = getArea(targetProperty.basicProperty);
  return address ? `<div class="c-linePropertyBlockContent__menseki">所在地：${address}</div>` : '';
}

/**
 * 付随する情報をHTMLで取得
 * @param property
 * @return {string}
 */
const getAnotherDataHTML = property => {
  let result = '';

  if (constProperty.kubun.tochi === property.type && !_.isNil(property.propertyLand?.kenchiku_joken)) {
    const label = _.find(constProperty.kenchikujoken, (kenchiku) => kenchiku.value === property.propertyLand?.kenchiku_joken);
    const labelText = label ? label.text : 'その他';

    result += `<div class="c-linePropertyBlockContent__menseki">建築条件：${labelText}</div>`;
  }

  if (constProperty.kubun.jigyou === property.type && !_.isNil(property.propertyPrice?.sotei_rimawari)) {
    result += `<div class="c-linePropertyBlockContent__menseki">想定利回り：${property.propertyPrice?.sotei_rimawari}%</div>`;
  }

  if (constProperty.kubun.jigyou === property.type && !_.isNil(property.propertyPrice?.sotei_shunyu)) {
    result += `<div class="c-linePropertyBlockContent__menseki">想定収入：${(property.propertyPrice?.sotei_shunyu).toLocaleString()}円</div>`;
  }

  return result;
}

/**
 * get property image match condition
 * @param property
 * @returns {string}
 */
const getPropertyImagePath = function getPropertyImagePath(property) {
  if (!property.propertySavedImages.length) {
    return DEFAULT_IMAGE;
  }
  const propertySavedImages = _.filter(property.propertySavedImages, (savedImage) => savedImage.propertyImages[0]?.division === '4');
  if (propertySavedImages.length) {
    return getImageURL(propertySavedImages);
  }
  return getImageURL(property.propertySavedImages);
};

/**
 * @param propertySavedImages
 * @returns {*}
 */
function getImageURL(propertySavedImages) {
  const propertySavedImageInOrder = _.minBy(propertySavedImages, (propertySavedImage) => propertySavedImage.propertyImages[0]?.image_ordering);
  const imageURI = propertySavedImageInOrder?.uri;
  return imageURI ? 'https://img.sumitas.jp/ph2/' + imageURI : DEFAULT_IMAGE;
}
