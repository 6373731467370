
import TheMainContainer from "~/components/container/TheMain.vue";
import TheMessage from "~/layouts/message.vue";
import PropertyHeaderContainer from "~/components/container/property/PropertyHeader.vue";
import constPrivacy from "~/const/privacy";

export default {
  name: "matchingMail",
  head: {
    title: '物件ページ',
    link: [
      {rel: 'stylesheet', href: '/matchingMail/css/colorbox.css'},
      {rel: 'stylesheet', href: 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css'},
      {rel: 'stylesheet', href: '/matchingMail/css/styles.css'}
    ]
  },
  components: {
    TheMainContainer,
    TheMessage,
    PropertyHeaderContainer,
  },
  methods: {
    isPrivacyRoute() {
      return this.$route.name === constPrivacy.routePrivacy
    }
  }
}
